@import 'src/styles/shared';

.homepage {
  width: 100%;
  height: 100%;

  background: rgba(255, 255, 255, 0.8) !important;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  span.spacer:first-child {
    flex: 1 1 50%;
  }

  span.spacer:last-child {
    flex: 1 1 50%;
  }

  .flex-1 {
    flex: 1;
  }

  .homepage-group {
    //position: absolute;
    //width: 100%;
    //height: auto;
    //top: px-to-rem(340px);
    //bottom: px-to-rem(420px);

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    img {
      width: px-to-rem(395px);
    }

    .homepage-title {
      margin-top: px-to-rem(60px);
      color: $blue-dark;
      font-size: px-to-rem(80px);
      font-weight: 700;
    }
  }

  .homepage-button-enter {
    //position: absolute;
    //top: px-to-rem(840px);
    width: px-to-rem(106px);
    height: px-to-rem(50px);

    background-color: $blue-medium !important;
    border-radius: px-to-rem(25px);

    font-size: 1.25rem;
    font-weight: 600;
    color: white;
    text-transform: uppercase;

    display: flex;
    justify-content: center;
    align-items: center;

    transition: background-color $transition-delay ease-out;
  }

  .homepage-loader-bar {
    position: fixed;
    top: 0;
    left: 0;
    height: 2px;
    width: 0;
    background-color: $blue-dark;
    //transition: all .3s linear;
    transition: 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
  }
}

@media (hover: hover) and (pointer: fine) {
  .homepage {
    .homepage-button-enter:hover {
      cursor: pointer;
      background-color: $blue-dark !important;
    }
  }
}