@import 'src/styles/shared';

.thermometer {
  position: fixed;
  bottom: px-to-rem(47px);
  left: px-to-rem(64px);

  width: px-to-rem(70px);
  height: px-to-rem(220px);

  img {
    width: 100%;
    height: 100%;
  }
}