@import 'src/styles/shared';

.step-list {
  z-index: 800; // above Main (100) & Annotations (500)
  position: absolute;

  //pointer-events: none; // TODO UNCOMMENT IN PROD

  top: 0;
  padding-top: px-to-rem(49px);
  left: px-to-rem(328px);

  display: flex;
  flex-direction: column;

  .vertical-line {
    //z-index: 900; // display below buttons
    position: absolute;
    width: px-to-rem(8px); // add margin to width
    margin-left: px-to-rem(-41px-4px);
    top: 0;
    height: 100%;
    background-color: $orange;
  }

    /* ===== Scrollbar CSS ===== */
    /* Firefox */
    * {
      scrollbar-width: thin;
      scrollbar-color: #ffffff #2f2f2f;
    }

    /* Chrome, Edge, and Safari */
    *::-webkit-scrollbar {
      width: 8px;
    }

    *::-webkit-scrollbar-track {
      background: #2f2f2f;
    }

    *::-webkit-scrollbar-thumb {
      background-color: #ffffff;
      border-radius: 4px;
      border: 1px solid #2f2f2f;
    }

  &.right {
    // ##########################################################
    background-color: rgba(229, 229, 229, .5);
    backdrop-filter: blur(30px) brightness(1.15);

    top: 0;
    right: 0;

    width: px-to-rem(656px);
    height: 100%;

    padding-top: px-to-rem(140px); // 170-30
    font-size: px-to-rem(22px);
    line-height: px-to-rem(34px);

    .step-list-content {
      pointer-events: auto; // allow scrolling
      // height: 100%; //px-to-rem(450px);
      right: 32px;
      position: absolute;
      top: 10rem;
      bottom: 10rem;
      overflow-y: auto;

      display: flex;
      flex-direction: column;
      justify-content: space-between;


      .step-list-content-inner {
        pointer-events: auto;

        max-height: 160%;
        overflow-y: auto;

        &.limit-carrousel {
          //background-color: rgba(127, 255, 255, 0.5) !important; // TODO DEBUG
          max-height: 100%;
        }

        // margin: px-to-rem(40px) px-to-rem(32px) 0 0;
        margin: 0;
        // padding: 0 px-to-rem(40px) 10px px-to-rem(33px);
        padding: 0 px-to-rem(40px) 10px px-to-rem(33px);
      }

      p:first-of-type {
        margin-top: 0;
      }

      ul {
        padding-inline-start: px-to-rem(27px);
      }

      li::before {
        display: inline-block;
        content: "";
        border-bottom: px-to-rem(2px) dashed $orange;
        border-left: px-to-rem(2px) dashed $orange;
        width: px-to-rem(45px);
        height: px-to-rem(13px);
        margin-left: px-to-rem(-60px); // minus container margin
        margin-right: px-to-rem(15px);
        margin-top: px-to-rem(-13px);
        vertical-align: middle;
      }


    }

      /* ===== Scrollbar CSS ===== */
      /* Firefox */
      * {
        scrollbar-width: thin;
        scrollbar-color: #ffffff #2f2f2f;
      }

      /* Chrome, Edge, and Safari */
      *::-webkit-scrollbar {
        width: 8px;
      }

      *::-webkit-scrollbar-track {
        background: #2f2f2f;
      }

      *::-webkit-scrollbar-thumb {
        background-color: #ffffff;
        border-radius: 4px;
        border: 1px solid #2f2f2f;
      }
  }
}