@import 'src/styles/shared';

$circle-width: 68px; // TODO px-to-rem() ?
$circle-radius: $circle-width / 2;

.step-list-item {
  //background-color: red;

  height: px-to-rem(68px);
  margin: px-to-rem(12px) 0;

  .step-list-title {
    //position: absolute;
    font-size: px-to-rem(30px);
    font-weight: 600;
    color: $blue-dark;

    background-color: rgba(229, 229, 229, .5);
    backdrop-filter: blur(30px) brightness(1.15);
    transition: all $transition-delay ease;

    //height: px-to-rem(69px);
    padding: px-to-rem(15px) px-to-rem(26px);
    border-radius: px-to-rem(69px/2);
  }

  .step-list-item-marker {
    position: absolute;
    margin-left: px-to-rem(-41px); // copied from StepList.scss

    transform: translateX(-50%) scale(1);

    width: px-to-rem($circle-width);
    height: px-to-rem($circle-width);

    transition: all $transition-delay ease;

    circle#backCircle {
      fill: $blue-dark;
      stroke: white;
      stroke-width: 0;
      stroke-location: outer;
      cx: px-to-rem($circle-radius) !important;
      cy: px-to-rem($circle-radius);
      r: px-to-rem(16px);

      transition: all $transition-delay ease;
    }

    circle#frontCircle {
      fill: transparent;
      stroke: $blue-light;
      stroke-width: px-to-rem(4px);
      stroke-location: outer;
      cx: px-to-rem($circle-radius) !important;
      cy: px-to-rem($circle-radius);
      r: px-to-rem(10px);
    }

    text {
      text-anchor: middle;
      fill: white;
      font-size: px-to-rem(26px);
      font-weight: 600;
      transform-origin: px-to-rem($circle-radius);
    }
  }

  &.highlighted {
    transition: all $transition-delay ease;

    .step-list-title {
      background-color: white;
    }

    .step-list-item-marker {
      transform: translateX(-50%) scale(1.25);

      circle#backCircle {
        stroke: white;
        stroke-width: px-to-rem(4px);
      }

    }

  }
}

@media (hover: hover) and (pointer: fine) {
  .step-list-item {
    &:hover {
      cursor: pointer;

      .step-list-title {
        background-color: white;
        //transition: all $transition-delay ease;
      }

      .step-list-item-marker {
        transform: translateX(-50%) scale(1.25);

        circle#backCircle {
          stroke: white;
          stroke-width: px-to-rem(4px);
          //transition: all $transition-delay ease;
        }

        transition: all $transition-delay ease;
      }
    }
  }
}