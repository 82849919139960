@import 'src/styles/shared';

.header {
  pointer-events: none;
  //@include debug-child-div-random-background(10, 0.15); // TODO REMOVE DEBUG

  z-index: 1000; // above Main and Canvas
  position: absolute;
  top: px-to-rem(30px);
  left: px-to-rem(30px);
  width: px-to-rem(197px);
  height: auto;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  background-color: white;
  color: $blue-dark;

  img {
    height: px-to-rem(90px);
  }

  .header-title {
    height: px-to-rem(100px);

    text-align: center;
    font-size: px-to-rem(30px);
    font-weight: 700;
  }
}

//@media (hover: hover) and (pointer: fine) {
//  .header {
//    &:hover {
//      cursor: pointer;
//    }
//  }
//}