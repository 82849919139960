@import 'src/styles/shared';

.sky-controls-container {
  position: absolute;
  left: px-to-rem(132px);
  bottom: px-to-rem(30px);
  width: px-to-rem(1000px);
  height: px-to-rem(200px);
  padding: px-to-rem(26px) px-to-rem(26px) px-to-rem(34px) px-to-rem(26px);
  border-radius: px-to-rem(24px);

  display: flex;
  flex-direction: row;
  justify-content: space-around;

  background-color: rgba(white, .66);
  backdrop-filter: blur(30px) brightness(1.25);


  .sky-controls-wrapper {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: center;
  }

  .sky-controls-title {
    color: $blue-dark;
    font-size: px-to-rem(22px);
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;
    width: 100%;
  }

  .sky-controls-label-before,
  .sky-controls-label-after {
    color: $blue-dark;
    font-size: px-to-rem(16px);
    font-weight: 700;
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: center;

    &, & > svg > * {
      transition: all $transition-delay;
    }

    svg {
      width: px-to-rem(36px);
      height: px-to-rem(36px);

      g {
        stroke: $blue-dark;
      }
    }

    &.label-disabled {
      color: $grey-disabled;

      svg {
        g {
          stroke: $grey-disabled;
        }
      }
    }
  }

  .sky-controls {
    padding: px-to-rem(24px);
    background-color: white;
    border-radius: px-to-rem(35px);
    margin: 0 px-to-rem(18px);

    .rc-slider {
      padding: 0;
    }

    .rc-slider-rail {
      z-index: 100;
      height: px-to-rem(22px);
      border-radius: px-to-rem(11px);
      box-shadow: inset px-to-rem(2px) px-to-rem(2px) px-to-rem(2px) rgba(black, 0.25);
    }

    .rc-slider-track {
      visibility: hidden;
      display: none;
    }

    .rc-slider-step {
      height: px-to-rem(22px);
    }

    .rc-slider-dot,
    .rc-slider-dot-active {
      z-index: 50;
      position: absolute;
      top: px-to-rem(-6px);
      width: 0;
      height: px-to-rem(34px);
      vertical-align: middle;
      background-color: $grey-disabled;
      border-radius: px-to-rem(5px);
      //cursor: pointer;
      border: px-to-rem(2.5px) solid $grey-disabled;
    }

    .rc-slider-mark {
      display: none;
    }

    .rc-slider-handle {
      z-index: 1000;
      opacity: 1;
      transition: all $transition-delay;
    }

    .rc-slider-handle-dragging {
      transition: all 0s;
      box-shadow: none;
    }

    &.sky-controls-time {
      width: px-to-rem(220px);
      height: px-to-rem(70px);

      .rc-slider-rail {
        background: linear-gradient(0.25turn, #5169B6, #C4D1E6, #5169B6);
      }

      .rc-slider-handle {
        background: center / cover no-repeat url('../../assets/icons/soleil.png');
        border: none;
        height: px-to-rem(90px);
        width: px-to-rem(90px);
        margin-top: px-to-rem(-32px); // padding=24px; (height difference / 2)=10; => 24+10=34
      }
    }

    &.sky-controls-season {
      width: px-to-rem(106px);
      height: px-to-rem(70px);

      .rc-slider-rail {
        background-color: #5169B6;
      }

      .rc-slider-handle {
        background: center / cover no-repeat url('../../assets/icons/season-handle.png');
        border: none;
        height: px-to-rem(38px);
        width: px-to-rem(38px);
        margin-top: px-to-rem(-7px); // padding=24px; (height difference / 2)=10; => 24+10=34
      }

      .sky-controls-label-before,
      .sky-controls-label-after {
        color: red !important;
      }
    }
  }

  .sky-controls-orientation {
    height: px-to-rem(200px);
    margin: px-to-rem(-26px);
    padding: px-to-rem(14px);

    svg {
      height: 100%;
    }
  }
}