@import './variables';
@import './functions';

.hidden {
  visibility: hidden;
  pointer-events: none;
  opacity: 0;
  transition: opacity ease-in-out .5s;
}

.visible { // TODO test
  opacity: 1;
  transition: opacity ease-in-out .5s;
}

.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
}

.full-page {
  width: 100%;
  height: 100%;
}

.flex {
  display: flex;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-space-between {
  justify-content: space-between;
}

.flex-center {
  justify-content: center;
  align-items: center;
}

.flex-align-top {
  align-items: flex-start !important;
}

.flex-row {
  flex-direction: row;
}

.flex-column {
  flex-direction: column;
}

// any input or textarea placeholder
:placeholder-shown {
  color: $grey-light;
  font-style: italic;
}

.form {
  & * {
    font-family: "Source Sans Pro", sans-serif;
  }

  & input {
    padding: 0.5rem;
    border: none;
    font-size: px-to-rem(20px);
    width: px-to-rem(460px);
    margin-top: 1rem;
  }

  & button {
    border: none;
    padding: 0.5rem;
    font-size: px-to-rem(30px);
    font-style: italic;
    width: 10rem;
    margin-top: px-to-rem(50px);

    color: #FFFFFF;
    background-color: $blue-light;
    transition: $transition-delay-fast background-color ease-out;

    &:disabled {
      background-color: rgba($blue-light, 0.5);
    }

    &:hover:not(:disabled){
      background-color: $blue-dark;
      cursor: pointer;
    }
  }
}


.clickable:hover {
  cursor: pointer;
}