// duplicated from _functions.scss to prevent circular dependency
@function px-to-rem($size, $pxRef: $base-font-size) {
  $rem-size: $size / $pxRef;

  @return #{$rem-size}rem;
}

// Main Color
$blue-light: #6C8CC0;
$blue-medium: #6C8CC0; // TODO REMOVE ?
$blue-dark: #002395;

$orange: #FF5800;

$grey-lighter: #E5E5E5;
$grey-light: #D8D9DB;
$grey-dark: #474747;
$grey-disabled: #717171;

$text-black: #020202;

// Dimensions
$base-font-size: 16px;
$header-height: 0px; //px-to-rem(260px); // 16.25rem
$side-panel-width: px-to-rem(656px);

// Transitions
$transition-delay-fast: 0.1s;
$transition-delay: 0.3s;
$transition-delay-slow: 2s;

//#region Header

// Background
$customer-header-color: fuchsia;
$sales-header-color: $blue-medium;
$admin-header-color: $blue-dark;

// Title
$customer-room-name-color: $grey-dark;
$sales-room-name-color: #FFFFFF;
$admin-room-name-color: #FFFFFF;
$configuration-name-color: #FFFFFF;

$icon-back-color: $blue-dark;
$icon-fore-color: #FFFFFF;

//#endregion