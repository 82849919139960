@import 'src/styles/shared';

$circle-width: 60px;
$circle-radius: $circle-width / 2;

.side-panel {
  .side-panel-title {
    background-color: rgba(255, 255, 255, 1);

    position: fixed;
    display: flex;
    flex-direction: row;
    align-items: center;

    border-radius: px-to-rem(40px);

    &.right {
      top: px-to-rem(60px);
      margin-left: px-to-rem(-40px);
      padding: px-to-rem(10px);
      padding-right: px-to-rem(20px);
    }

    &.fullpage {
      top: px-to-rem(260px);
      height: px-to-rem(80px);
      margin-left: px-to-rem(10px);
      padding: 0;
      padding-right: px-to-rem(20px);

      .side-panel-title-marker {
        transform: scale(1.3333);
      }

      .side-panel-title-text {
        font-size: px-to-rem(40px);
      }
    }

    .side-panel-title-text {
      float:left;
      margin-left: px-to-rem(20px);
      color: $blue-dark;
      font-size: px-to-rem(26px);
      font-weight: 700;
    }

    .side-panel-title-marker {
      width: px-to-rem($circle-width);
      height: px-to-rem($circle-width);

      circle#backCircle {
        fill: $blue-dark;
        cx: px-to-rem($circle-radius) !important;
        cy: px-to-rem($circle-radius);
        r: px-to-rem(30px);
      }

      circle#frontCircle {
        fill: transparent;
        stroke: $blue-light;
        stroke-width: px-to-rem(10px);
        cx: px-to-rem($circle-radius) !important;
        cy: px-to-rem($circle-radius);
        r: px-to-rem(20px);
      }

      text {
        text-anchor: middle;
        fill: white;
        font-size: px-to-rem(26px);
        font-weight: 600;
        transform-origin: px-to-rem($circle-radius);
      }
    }
  }
}