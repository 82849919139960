@import 'src/styles/shared';

.fixedBoxThumbnailAnnotationsContainer {
  //background-color: rgba(255, 0, 0, 0.8);
  //pointer-events: none; // TODO uncomment for prod
  pointer-events: auto; // TODO debug

  position: fixed;
  //left: px-to-rem(30px + 197px);
  left: px-to-rem(240px); // 10px from child margins 250-10px
  top: px-to-rem(39px); // 49px-10px
  //right: $side-panel-width;
  right: px-to-rem(720px); // 730px-10px

  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;

  //padding: 0 0 px-to-rem(100px) px-to-rem(150px);

  .annotation.boxThumbnail {
    //background-color: rgba(0, 255, 0, 0.8); // TODO REMOVE DEBUG

    //width: px-to-rem(168px);
    //height: px-to-rem(168px);

    display: flex;
    flex-direction: row;
    align-items: center;
    //justify-content: space-around;

    //margin-top: px-to-rem(28px);
    //margin: px-to-rem(10px) px-to-rem(10px) px-to-rem(10px) px-to-rem(10px);
    margin: px-to-rem(10px);

    //&:first-child {
    //  margin-left: 0;
    //}

    &.blurred-background {
      pointer-events: auto; // otherwise clicking exits state

      width: px-to-rem(168px);
      height: px-to-rem(168px);

      background-color: rgba(255, 255, 255, 0.8);
      backdrop-filter: blur(2px); // TODO keep this?
      border-radius: px-to-rem(20px);
      z-index: 1;

      display: inline-block;

      padding: px-to-rem(10px);
      //box-shadow: 0.2rem 0.2rem 0.5rem 0 rgba(black, 0.2) !important;
      border: solid px-to-rem(3px) transparent;

      transition: all $transition-delay-fast ease;

      &.boxThumbnailSelected {
        background-color: rgba(255, 255, 255, 1);
        border: solid px-to-rem(3px) $orange;
      }
    }

    .description {
      //margin-left: px-to-rem($circle-radius);
      max-width: px-to-rem(500px);

      //padding: px-to-rem(12px);
      white-space: normal;
      word-wrap: break-word;
      text-align: center;
      color: $blue-dark; //$dark-color; // changed from orange to blue to respect mock-up
      font-size: px-to-rem(12px);
      font-weight: 700;
      text-transform: uppercase;

      &.tablet {
        font-size: 1rem;
      }

      &.mobile {
        font-size: 0.85rem;
      }
    }

    .thumbnail {
      background-color: blue;

      width: px-to-rem(100px);
      height: px-to-rem(90px);

      border-radius: px-to-rem(20px);
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
      }

      position: absolute;
      left: px-to-rem(32px);
      right: px-to-rem(32px);
      bottom: px-to-rem(16px);
    }

    .title {
      color: $blue-dark;
      font-size: 1.6rem;
      font-weight: 700;

      margin-left: px-to-rem(-84px);
      padding: px-to-rem(20px) px-to-rem(28px);

      &.tablet {
        font-size: 1.45rem;
        padding: px-to-rem(16px) px-to-rem(22px);
      }

      &.mobile {
        font-size: 1.3rem;
        padding: px-to-rem(14px) px-to-rem(20px);
      }
    }
  }
}

@media (hover: hover) and (pointer: fine) {
  .fixedBoxThumbnailAnnotationsContainer {
    .annotation.boxThumbnail {
      &:hover {
        cursor: pointer;

        &.blurred-background {

          border: solid px-to-rem(3px) $orange;
          transition: all $transition-delay-fast ease;
        }
      }
    }

  }
}